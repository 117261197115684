import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

function About() {
  return (   
  <div className="page-cont about">   
<div className="page-header">
  <Container className="p-sm-0">
  <Row>
    <Col sm={8}>
    <h2>About uxengine24 Dot Com, the website you are reading now</h2> 
    </Col>    
    </Row>
  </Container>
</div>

<Container className="p-sm-0">
<Row className="mb-4">
  <Col sm={8}>
  <h3>UXengine24</h3>
  <p>UXengine24<sup>TM</sup> was created for UI/UX enthusiast as a referencial resource where they can learn about topics that will help sharpen their skills.</p>
  <p>The platform offers information on both User Experience design as well as the development. Therefore you can increse the UI/UX knowledge under full-stack scale.</p>
  <p>UXengins24 is a ReactJS application, Created & Managed<br/> by Saminda Godevithane</p>
</Col>
</Row>
  <Row className="mb-4">  
    <Col sm={8}>
    <h3>Saminda Godevithane</h3>
    <p>A full-stack UI/UX engineer, an expert in planning/designing User Experiences, consultant and writer. With the years of experience, my creativity is up to delivering effective and elegant web and native app solutions under many domains such as e-Commerce markets, Education, Travel, Health, etc.</p>
    {/* <p>I’ve seen many ups and downs of what agencies do right and wrong. I keep learning, the learning never ends in the software industry and I love to share what I discover.</p> */}
    <p>With my passion to this fascinating UI/UX subject I keep learning, the learning never ends in the software industry and I love to share what I discover.</p>
    {/* <p>I can help you more narrowly position your agency to attract more qualified leads and learn gaining the user engagement on web and native application projects and increase revenues.</p> */}
    <img className="profile-img" src={require('../assets/images/profile.jpg')} alt="UXEngine-Pro" />
    <p className="mt-3"><i>"As a UI/UXer I transform any domain into a unique visual language."</i></p>
    <NavLink className="btn-3" to='/about_saminda_godevithane'>Read More</NavLink>
    </Col>
    {/* <Col sm={4}>
    
    </Col>    */}
  </Row>

</Container> 
    </div>
  );
}

export default About;
