import React from 'react';
// import { Button,Container,Row,Col } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

function AboutSaminda() {
  return (   
  <div className="page-cont about-saminda">   
<div className="page-header">
  <Container className="p-sm-0">
  <Row>
    <Col sm={8}>
    <h2>Saminda Godevithane</h2>    
    </Col>
    </Row>
  </Container>
</div>
<Container className="p-sm-0">
  <Row className="mb-4 flex-column-reverse flex-sm-row">
    <Col sm={8}>
    <p>A full-stack UI/UX engineer, an expert in planning/designing User Experiences, consultant and writer. With the years of experience, my creativity is up to delivering effective and elegant web and native app solutions under many domains such as e-Commerce markets, Education, Travel, Health, etc.</p>
    <p>I’ve seen many ups and downs of what agencies do right and wrong. I keep learning, the learning never ends in the software industry and I love to share what I discover.</p>
    {/* <p>I can help you more narrowly position your agency to attract more qualified leads and learn gaining the user engagement on web and native application projects and increase revenues.</p> */}
    <p>My expertise and interest lies in both UI/UX design as well as front-end technologies, including HTML/5, CSS, JavaScript and JS based frameworks such as React. Therefore I have great chemistry of working with dev teams to achive the goals.</p>
    <p>With my passion to this fascinating UI/UX subject I keep learning, the learning never ends in the software industry and I love to share what I discover.</p>
    <p className="mt-3"><i>"As a UI/UXer I transform any domain into a unique visual language."</i></p>  
    </Col>
    <Col sm={4}>
    <figure>
    <img className="profile-img" src={require('../assets/images/saminda_2.jpg')} alt="UXEngine-Pro" />
    </figure>
    {/* <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Accordion Item #1
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Accordion Item #2
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
        Accordion Item #3
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
      </div>
    </div>
  </div>
</div> */}
    </Col>
  </Row>
  <Row className="mb-4 section">
  <Col sm={7}>
  <h3>User Experience(UX) design process</h3>
    <p>Good UX design creates a positive experience for your user by fulfilling their needs. I follow below process and I try my best to keep it inline across the projects.</p>
  </Col>
    <Col sm={12}>    
  <figure>
  <img src={require('../assets/images/ux_design_process.png')} alt="UX design process" />
  </figure> 
  <p>What is it all about?</p>
    </Col>
  <Col sm={4} className="mb-3">
  {/* <img className="profile-img" src={require('../assets/images/process_1.png')} alt="UXEngine-Pro" /> */}
  <h5>1. Research</h5>
  <p>Study user behaviors and user needs, find design opportunities.</p>
  <ul>
  <li>User demographics</li>
<li>Define user goals</li>
<li>Define user challenges</li>
<li>Define metrics & form hypothesis</li>
  </ul>
  </Col>
  <Col sm={4} className="mb-4">
  {/* <img className="profile-img" src={require('../assets/images/process_2.png')} alt="UXEngine-Pro" /> */}
  <h5>2. Empathy</h5>
  <p>Conduct research to develop an understanding of your users. Be the user.</p>
  <ul>
  <li>User personas</li>
<li>User scenarios</li>
<li>User testing</li>
<li>User experiance map</li>
  </ul>
  </Col>
  <Col sm={4} className="mb-3">
  {/* <img className="profile-img" src={require('../assets/images/process_3.png')} alt="UXEngine-Pro" /> */}
  <h5>3. Create</h5>
  <p>Make it simple & clear.</p>
  <ul>
  <li>Sketching</li>
<li>Creating wireframes</li>
<li>Creating prototypes (Low fidelity/High fidelity)</li>
<li>Creating a design specification</li>
<li>Creating design systems</li>
  </ul>
  </Col>
  <Col sm={4} className="mb-3">
  {/* <img className="profile-img" src={require('../assets/images/process_4.png')} alt="UXEngine-Pro" /> */}
  <h5>4. Test</h5>
  <p>Data don't lie. Evaluate the functionality of a software application.</p>
  <ul>
  <li>Usability tests</li>
<li>Review metrics</li>
<li>Track usage</li>
<li>Inform next iteration</li>
  </ul>
  </Col>
  <Col sm={4} className="mb-3">
  {/* <img className="profile-img" src={require('../assets/images/process_5.png')} alt="UXEngine-Pro" /> */}
  <h5>5. Develop</h5>
  <p>Stick to the design.</p>
  <ul>
    <li>Development guide and kit</li>
    <li>Product integration and build</li>
    <li>Quality control testing and launch</li>
    <li>Itertions</li>
  </ul>
  </Col> 
  </Row>
  <Row className="mb-4">
    <h3 className="mb-3">Technology</h3>
    <p className="d-flex flex-column"><strong>Design Mockups and Prototypes:</strong> Figma, Adobe XD, Adobe Photoshop, Adobe Illustrator, Zeplin, Pen and Book</p>
    <p className="d-flex flex-column"><strong>Code:</strong> HTML, CSS3, Javascript, SCSS pre-processing</p>
    <p className="d-flex flex-column"><strong>UI Kits and frameworks:</strong> Material UI, Bootstrap, Ant Design, Tailwind CSS</p>
    <p className="d-flex flex-column"><strong>UI Frameworks and architecture:</strong> React JS + SAGA, Angular, Redux, Rest API, Storybook</p>
    <p className="d-flex flex-column"><strong>PHP based frameworks:</strong> Codeigniter, Laravel</p>
    <p className="d-flex flex-column"><strong>CMS:</strong> Wordpress, Magento</p>
    <p className="d-flex flex-column"><strong>Source control and PM supportive tools</strong> GIT, Jira</p>
  </Row>
  <Row className="mb-4">
  <h3>Full-stack workflow</h3>
  <figure>
  <img src={require('../assets/images/banner_top_2.jpg')} alt="Banner" />
    <figcaption>Hospital App</figcaption>
  </figure>
  </Row>
  <Row className="mb-4">
  <Col sm={7} className="mb-3">
  <h3>Spare Time</h3>
  <p>In my spare time I play guitar or keyboard. Love to travel (Galle, Galle and Galle). Reading and collecting books (Lots of books) especially Sci-fi. Sometimes I enjoy watching movies. Cycling, Football Cricket and Badminton.</p>
  </Col>
  </Row>
</Container> 
    </div>
  );
}

export default AboutSaminda;
