import React, {Component} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import renderHTML from 'react-render-html';

export class WorkPost extends Component{
  state= {
    post: {},
    isLoaded: false
  }
  componentDidMount(){
    axios.get(`https://blog.uxengine24.com/wp-json/wp/v2/works/${this.props.match.params.id}`)
    .then(res =>  this.setState({
      post: res.data,
      isLoaded: true
    }))
    .catch(err => console.log(err));
  }
  render() {
    const {post, isLoaded } = this.state;
    if(isLoaded){
      return( <div className="page-cont">
      <Container className="p-sm-0">
      <ul className="breadcrumbs">
          <li><Link to="/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"/>
  <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"/>
</svg>Home</Link>
</li>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg>
<li><Link to="/works">Works</Link></li>
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg>
<li>
{post.title.rendered}
</li>
</ul>
        <Row className="single-post">
          <Col sm={12} className="single-post-cont">        
          <h1>{post.title.rendered}</h1>
          <p className="exerpt">{renderHTML(post.excerpt.rendered)}</p>
          <div>{renderHTML(post.content.rendered)}</div>
          </Col>
        </Row>
      </Container>
          </div>
        )
    }
    return <Container className="p-sm-0"><div>Loading...</div></Container>
  }
}
export default WorkPost;