import React from 'react';
// import { Button,Container,Row,Col } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';

function Resources() {
  return (   
  <div className="page-cont about">   
<div className="page-header">
  <Container>
  <Row>
    <Col sm={7}>
    <h2>Resources</h2>  
    </Col>
    </Row>
  </Container>
</div>
<Container>
  <Row className="mb-4">   
    <Col sm={8}>
    <p>Set of Links</p>
    <a target="_blank" href="https://docs.google.com/document/d/1_Olq2zhsy44B5cnp2jNzwzY_EPSxhi7yqK-3RfDJ7U4/edit?usp=sharing" rel="noopener noreferrer">UI/UX Interview Questions</a>
    </Col>    
  </Row>
</Container> 
    </div>
  );
}

export default Resources;
