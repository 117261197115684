import React, {Component, Fragment} from 'react';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import PostItem from './post_item';


export class BlogLoadList extends Component{
  
  state={
    posts: [],
    isLoaded: false
  }

  componentDidMount(){
    axios.get('https://blog.uxengine24.com/wp-json/wp/v2/blog')
    .then(res => this.setState({
      posts: res.data,
      isLoaded: true
     }))
    .catch(err => console.log(err));
  }
  render(){
    const {posts, isLoaded} = this.state;
    if(isLoaded){
      return(
        <Fragment>{
          posts.map(post => (
            <div className="featured-item d-block d-sm-flex" key={post.id}>
              <img variant="top" src={post.better_featured_image.media_details.sizes.medium.source_url} alt={post.better_featured_image.alt_tag} />
               <div>
               <h3><Link to={`/blog/${post.id}?${post.slug}`}>{post.title.rendered}</Link></h3>
                <div>{renderHTML(post.excerpt.rendered)}</div> 
                {/* <div className="date">{post.date}</div> */}
                {/* <Link to={`/${post.id}?${post.slug}`}>Read More</Link>*/}
                </div> 
            </div> 
        ))}
        </Fragment>
      );
    }
    return <div>Loading...</div>;
    
  }
}

export default BlogLoadList;