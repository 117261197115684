import React, { useState, useEffect } from "react";

const ScrollViewInner = () => {
    const [showTopHeader, setShowTopHeader] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 500) {
                setShowTopHeader(true);
            } else {
                setShowTopHeader(false);
            }
        });
    }, []);
    return (
        <>
            {" "}
            {showTopHeader && (
                <div className="sub-header p-3">
                    <div className="container p-sm-0">
                        <div className="row">
                        <div className="col-sm-8 d-flex">
                            <a className="nav-link" href='#web-review'>Web Reviews</a>
                            <a className="nav-link" href='#package'>Package</a>
                        </div>
                        <div className="col-sm-4 d-flex justify-content-end">
                            <a className="btn-3" href='#aaa'>Get a review</a>
                        </div>
                        </div>
                        
                    </div>
                    </div>
            )}{" "}
        </>
    );
};
export default ScrollViewInner;