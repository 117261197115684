import React, {Component, Fragment} from 'react';
import { Card,Col } from 'react-bootstrap';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import PostItem from './post_item';


export class WorksLoad extends Component{
  
  state={
    works: [],
    isLoaded: false
  }

  componentDidMount(){
    axios.get('https://blog.uxengine24.com/wp-json/wp/v2/works')
    .then(res => this.setState({
      works: res.data,
      isLoaded: true
     }))
    .catch(err => console.log(err));
  }
  render(){
    const {works, isLoaded} = this.state;
    if(isLoaded){
      return(
        <Fragment>{works.map(work => (
            <Col sm={4}  className="post-grid-item" key={work.id}>
            <Card>
                <Link to={`/works/${work.id}?${work.slug}`}><Card.Img variant="top" src={work.better_featured_image.media_details.sizes.medium.source_url} alt={work.better_featured_image.alt_tag} /></Link>
                <Card.Body>
                {/* <Card.Title><Link to={`/work/${work.id}`}>{work.title.rendered}</Link></Card.Title> */}
                <h3><Link to={`/works/${work.id}?${work.slug}`}>{work.title.rendered}</Link></h3>
                <Card.Text> {renderHTML(work.excerpt.rendered)}</Card.Text> 
                </Card.Body>
            </Card>
            </Col>

        ))}
        </Fragment>
      );
    }
    return <div className="content-center"><img src={require('../assets/images/loading.gif')} alt="UXEngine-Pro" /></div>;
    
  }
}

export default WorksLoad;