import React from 'react';
import { Container } from 'react-bootstrap';
import BlogList from '../include/blog_load';
import SideBar from '../include/innerSideBar';

function Blog() {
  return (   
  <div className="page-cont blog">   
<div className="page-header">
    <Container className="p-sm-0">
      <h2>A blog with more UI/UX talks</h2>
    </Container>
  </div>
<Container className="p-sm-0">
  <div className="row">
    <div className="col-sm-8 list-view">
     <BlogList/>    
    </div>
    <div className="col-sm-4 sticky-scroll">
      <SideBar/>
    </div>
    </div>
</Container>
  
    </div>
  );
}

export default Blog;
