import React from 'react';
import { Container, Row } from 'react-bootstrap';
import ScrollViewInner from '../include/ScrollViewInner';

function webuseCons() {
  return (   
  <div id="web-review" className="page-cont works webuse_consult">   
<div className="page-header">
  <Container className="p-sm-0">
    <h2>Website Review and Consultancy</h2>    
    <p>Web usability affects all businesses. How does it affect yours?<br/>How usability influence revenues?</p>
  </Container>
  <ScrollViewInner />
</div>
<Container className="p-sm-0"> 
<section>
<Row>
    <div className="col-sm-8">    
    <p>A web review and consultancy takes on a project with a problem-solving attitude to analyze, suggest, recommend, and design the best solution for your website project</p>
    {/* <p>Web review and consultancy engagements are designed for specific clients and projects, but they all combine into one report that will introduce the best web solution for your business.</p> */}
    <p>We can generate a heatmap by analyzing your exsisting website and create a report including suggesions and recommendations to increase its usability to gain the user engagement.</p>
    <p>For further you can get a new UI/UX design for the website or the native application + design system which will ring the bell.</p>
    <img className="pt-4" src={require('../assets/images/heatmap.jpg')} alt="UXEngine-Pro" /> 
    </div>
    <div className="col-sm-4">
      <div className="circle-ad mt-4">
      <h4 className="mb-1"><strong>Get Your<br/>Web Site<br/>Reviewed!</strong></h4>
    <p className="mb-1">Email the site link and below details</p>
    <ol className="text-left">
      <li>Domain</li>
      <li>Product/Service</li>
      <li>Traget Audience</li>
      </ol>
    <a href="mailto:saminda@uxengine24.com" className="email"><strong>saminda@uxengine24.com</strong></a>
      </div>
    </div>
    </Row>
</section>  
{/* Testing Start */}
<section id="package" className="pt-5">
  <Row>
  <div className="page-header">
    <Container className="p-sm-0">
      <h2>What you get</h2>
      <p>You can’t fix what you can’t measure</p>
    </Container>
  </div>
  </Row>
  <Row>
    <div className="col-sm-8">
      <p>The package includes a visual of the website with a full review notes.</p>
      <img className="profile-img" src={require('../assets/images/web_review.jpg')} alt="UXEngine-Pro" /> 
    </div>
    </Row>
</section>
{/* Testing End */}
</Container>
  
    </div>
  );
}

export default webuseCons;
