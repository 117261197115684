import React from 'react';
import { NavLink } from 'react-router-dom';
// import ScrollViewButton from './ScrollViewButton';

function MainNav() {
  return (     
//   <Navbar sticky="top" bg="light" expand="lg" className="d-block">
//     {/* <Container>
//       <NavLink to='/'>
//     <Navbar.Brand>
//       <img src={require('../assets/images/logo.svg')} alt="UXEngine-Pro" className="logo" />
//       </Navbar.Brand>
//       </NavLink>
//       <span>UXengine24</span>
//     </Container> */}
//   <Container className="header pb-1"> 
//   <div className="top-header">
//   <NavLink to='/'>
//   <Navbar.Brand>
//   <img src={require('../assets/images/logo.svg')} alt="UXEngine-Pro" className="logo swing-in-left-fwd" />  
//   </Navbar.Brand>
//   </NavLink>
//   </div>
//   <Navbar.Toggle aria-controls="basic-navbar-nav" />
//   <Navbar.Collapse id="basic-navbar-nav">
//     <Nav className="ml-auto">      
//     <NavLink className="nav-link" to='/blog'>Blog</NavLink>
//       <NavDropdown title="Works" id="basic-nav-dropdown">
//         <NavDropdown.Item><NavLink className="nav-link" to='/works'>UI/UX Design + Dev</NavLink></NavDropdown.Item>
//         <NavDropdown.Item><NavLink className="nav-link" to='/web_usability_consultation'>Website Review and Consultancy</NavLink></NavDropdown.Item>
//         <NavDropdown.Divider />
//         <NavDropdown.Item><NavLink className="nav-link" to='/works'>More Services</NavLink></NavDropdown.Item>
//       </NavDropdown>    
//       {/* <NavLink className="nav-link" to='/uiux_design_guide_for_web_usability'>Writing</NavLink> */}
//       <NavLink className="nav-link" to='/about'>About</NavLink>
//       <NavLink className="nav-link" to='/contact'>Contact</NavLink>      
//     </Nav>
//   </Navbar.Collapse>
//   </Container>
// </Navbar>
<div className="sticky-top bg-white">
<nav className="navbar navbar-expand-lg header navbar-light pb-1 px-2 px-sm-0 container">
<div className="container">
	<NavLink to='/'><img src={require('../assets/images/logo.svg')} alt="UXEngine-Pro" className="logo" /></NavLink>
	<div className="logo-sub">
		{/* <img src={require('../assets/images/jingle.png')} alt="Jingle" className="logo" /> */}
	</div>
	<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav"  aria-expanded="false" aria-label="Toggle navigation">
		<span className="navbar-toggler-icon"></span>
	</button>
	<div className="collapse navbar-collapse" id="main_nav">
	<ul className="navbar-nav">
	{/* <li className="nav-item"><ScrollViewButton /></li> */}
	  <li className="nav-item active"><NavLink className="nav-link" to='/blog'>Blog</NavLink></li>
      <li className="nav-item dropdown has-megamenu">
				<a className="nav-link dropdown-toggle" href="/" data-bs-toggle="dropdown">Works</a>
				<div className="dropdown-menu megamenu" role="menu">
					<div className="row g-3">
						<div className="col-lg-3 col-6">
							<div className="col-megamenu">
              					<h4 className="title">Experience</h4>
								<ul className="list-unstyled">
									<li><NavLink className="nav-link" to='/works'>Projects</NavLink></li>
								</ul>
							</div>
						</div>    
						<div className="col-lg-3 col-6">
							<div className="col-megamenu">
              <h4 className="title">More Services</h4>
								<ul className="list-unstyled">
									{/* <li><NavLink className="nav-link" to='/web_usability_consultation'>Website Review and Consultancy</NavLink></li> */}
									<li><a className="nav-link" target="_black" href='https://uxbitz.uxengine24.com'>UXBitz<sup>TM</sup></a></li>
								</ul>
							</div>
						</div>
            <div className="col-lg-6 col-12 image-box">
							<div className="col-megamenu">
							<h4 className="title">What's New</h4>
							<img src={require('../assets/images/menu_img.jpg')} alt="UXEngine-Pro" className="logo mb-2" />
							{/* <p>New article about <span className="highlight-text">typography</span></p> */}
							<p>New article about typography</p>
							</div>
						</div>
					</div>
				</div>
			</li>
      <li className="nav-item"><NavLink className="nav-link" to='/about'>About</NavLink></li>
			<li className="nav-item"><NavLink className="nav-link" to='/contact'>Contact</NavLink></li>
		</ul>
		{/* <ul className="navbar-nav ms-auto">
			<li className="nav-item"><a className="nav-link" href="/"> Menu item </a></li>
			<li className="nav-item dropdown">
				<a className="nav-link  dropdown-toggle" href="/" data-bs-toggle="dropdown"> Dropdown right </a>
			    <ul className="dropdown-menu dropdown-menu-end">
				  <li><a className="dropdown-item" href="/"> Submenu item 1</a></li>
				  <li><a className="dropdown-item" href="/"> Submenu item 2 </a></li>
			    </ul>
			</li>
		</ul> */}
	</div>
</div>
</nav>
</div>
  );
}

export default MainNav;
