import React, {Component, Fragment} from 'react';
import { Card,Col } from 'react-bootstrap';
import renderHTML from 'react-render-html';
import { Link } from 'react-router-dom';
import axios from 'axios';
//import PostItem from './post_item';


export class BlogLoad extends Component{
  
  state={
    posts: [],
    isLoaded: false
  }

  componentDidMount(){
    axios.get('https://blog.uxengine24.com/wp-json/wp/v2/blog')
    .then(res => this.setState({
      posts: res.data,
      isLoaded: true
     }))
    .catch(err => console.log(err));
  }
  render(){
    const {posts, isLoaded} = this.state;
    if(isLoaded){
      return(
        <Fragment>{
          posts.map(post => (
            <Col loading="lazy" sm={4} className="post-grid-item" key={post.id}>
            <Card>
                <Link to={`/blog/${post.id}?${post.slug}`}><Card.Img variant="top" src={post.better_featured_image.media_details.sizes.medium.source_url} alt={post.better_featured_image.alt_tag} /></Link>
                <Link to={`/blog/${post.id}?${post.slug}`}>
                <Card.Body>                
                <h3><Link to={`/blog/${post.id}?${post.slug}`}>{post.title.rendered}</Link></h3>
                <Card.Text>{renderHTML(post.excerpt.rendered)}</Card.Text> 
                {/* <small className="text-muted date">{post.date}</small> */}
                </Card.Body>
                </Link>
                {/* <Card.Footer>
                <small className="text-muted date"> {post.date}</small>
                </Card.Footer> */}
            </Card>
            </Col>

        ))}
        </Fragment>
      );
    }
    return <div className="content-center"><img src={require('../assets/images/loading.gif')} alt="UXEngine-Pro" /></div>;
    
  }
}

export default BlogLoad;