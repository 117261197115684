import React from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import { Link } from 'react-router-dom';

function innerSideBar() {
  return (   
      <div> 
<div className="side-box p-4 w-100 shadow-box">
<h4 className="mb-1"><strong>Get Your Web Site<br/>Reviewed!</strong></h4>
    <p className="mb-1">Email below details</p>
    <ol className="text-left">
      <li>Website URL Link</li>
      <li>Product/Service</li>
      <li>Company, Traget Audience</li>
      </ol>
    <a href="mailto:saminda@uxengine24.com" className="email"><strong>saminda@uxengine24.com</strong></a>
      </div>      
    {/* 
    <hr/>
    <div className="side-box downloads">
    <h2 className="sub-title">Downloads</h2>
    <p>A list of cool downloads are on the way. <br/>Keep in touch!</p>
    <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
</svg>A Simple <a href={require('../assets/downloads/simple_design_system.xd')} download>design system</a> on XD, Free to use.</p> 
    </div>
    <hr />
    <div className="side-box">
    <div className="mars-plaque d-flex">
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-arrow-up" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
</svg>
    <p><strong>Mars</strong> 35,000,000 miles</p>
    </div>
    </div> */}
    </div>  
  );
}

export default innerSideBar;
