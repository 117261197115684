import React from 'react';
import { Container, Row } from 'react-bootstrap';
import WorksList from '../include/works_load';


function uiuxPortfolio() {
  return (   
  <div className="page-cont works">   
<div className="page-header">
  <Container className="p-sm-0">
    <h2>All made with years of experience</h2>
  </Container>
</div>
<Container className="p-sm-0">
<Row><WorksList/></Row>   
</Container>
  
    </div>
  );
}

export default uiuxPortfolio;
