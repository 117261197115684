import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import ScrollToTop from './include/ScrollToTop';
import MainNav from './include/MainNav';
import Footer from './include/Footer';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import About from './pages/About';
import AboutSaminda from './pages/AboutSaminda';
import Contact from './pages/Contact';
import Resources from './pages/Resources';
import Guide from './pages/Guide';
import Blog from './pages/Blog';
import uiuxPortfolio from './pages/uiuxPortfolio';
import webuseCons from './pages/webuseCons';
import SinglePost from './pages/SinglePost';
import WorkPost from './pages/WorkPost';
import Home from './pages/Home';
// import PageNotFound from './pages/Notfound';

function App() {
  return (   
  <Router onUpate={() => window.scrollTo(0, 0)}>
  <MainNav/>
  <ScrollToTop />
  <div className="main-container">    
  <Switch>    
    <Route exact path="/" component={Home}/>
    <Route exact path="/about" component={About}/>
    <Route exact path="/about_saminda_godevithane" component={AboutSaminda}/>
    <Route exact path="/works" component={uiuxPortfolio}/>
    <Route exact path="/web_usability_consultation" component={webuseCons}/>
    <Route exact path="/uiux_design_guide_for_web_usability" component={Guide}/>
    <Route exact path="/blog" component={Blog}/>
    <Route exact path="/contact" component={Contact}/>
    <Route exact path="/resources" component={Resources}/>
    <Route exact path="/33?study-for-web-usability" component={Blog}/>
    {/* <Route path="/post/:id" component={SinglePost}/> */}
    <Route path="/works/:id?/:slug?" component={WorkPost}/>
    <Route path="/blog/:id" component={SinglePost}/>
    {/* <Route path="*" component={PageNotFound}/> */}
    {/* <Route path="*"><PageNotFound/></Route>  */}
    {/* <Route path="/linkedin" component={() => { 
     window.location.href = "https://www.linkedin.com/in/saminda-godevithane"; 
     return null;
}}/> */}
  </Switch>

  </div>
  <Footer/>
  </Router>
  );
}
// function PageNotFound(){
//   return(
//     <div><h1>404</h1><p>This page is not found...</p></div>
//   )
// }
export default App;
