import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Guide() {
  return (   
  <div className="page-cont">   
<div className="page-header">
  <Container>
  <Row>
    <Col sm={7}>
    <h2>UI/UX Gateway to Enhance Web Usability</h2>  
    <p>A reading for web design</p>  
    </Col>
    </Row>   
  </Container>
</div>
<Container>
  <Row>
  <Col sm={4}>
    <img style={{ width: '100%' }} src={require('../assets/images/book_shadow.png')} alt="UXEngine-Pro" />    
    </Col>
    <Col sm={6} className="d-flex flex-column justify-content-center">
    <h3>Free eBook available soon!</h3>
      <p>It is an attempt to deliver an overview about UI/UX and a guidance for web design to ensure a great user experience.</p>
      </Col>
  </Row>
  </Container>
    </div>
  );
}

export default Guide;
