import React from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import BlogList from '../include/blog_load_list';
import SideBar from '../include/homeSideBar';
import { Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';

function Home() {
  return (   
  <div className="main-container home">   
<Container className="p-sm-0">
  {/* <Row className="spacer py-5">
    
  </Row> */}
  <Row className="spacer pt-4">
    <Col className="title-box" sm={8}>   
    <section className="main-banner d-flex align-items-center flex-wrap mb-5 fade-in-left">   
    <div className="banner-text">
    <h1 className="ms-header__title text-gradient">
      Design for Web Usability
    </h1>
    <p>A good learning resource for UI/UX enthusiasts</p>
    <Link className="btn-3" to={`/blog`}>Learn More</Link>
    </div>  
    <img className="banner-img" src={require('../assets/images/banner3.png')} alt="Banner" />   
    {/* <figcaption>UI/UX design for Business Traveller web application</figcaption> */}
    </section> 
    <section className="featured-list pt-4">
    <h2 className="sub-title mt-0">Featured Articles</h2>
    <BlogList/>
    </section>
    </Col>
    <Col sm={4} className="side-bar">
   <SideBar/>
    </Col>
  </Row>
</Container>
  
    </div>
  );
}

export default Home;
